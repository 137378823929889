/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"

import styled from "@emotion/styled"

import Transition from "./transition"
import Header from "./header"
import Footer from "./footer"

const LayoutContainer = styled.div`
  overflow: hidden;
`

const SVG = styled.svg`
  width: 0;
  height: 0;
  position: absolute;
`

const Main = styled.main``

const Layout = ({ location, children }) => {
  return (
    <>
      <SVG aria-hidden="true" focusable="false">
        <linearGradient id="gradient" x1="100%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor="#FFFF00" />
          <stop offset="100%" stopColor="#00FFFF" />
        </linearGradient>
        <filter id="outline">
          <feMorphology
            operator="dilate"
            radius="8"
            in="SourceGraphic"
            result="THICKNESS"
          />
          <feComposite
            operator="out"
            in="THICKNESS"
            in2="SourceGraphic"
          ></feComposite>
        </filter>
        <filter id="goo">
          <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="30" />
          <feColorMatrix
            in="blur"
            values="1 0 0 0 0 0 1 0 0 0 0 0 1 0 0 0 0 0 30 -7"
          />
        </filter>
      </SVG>
      <LayoutContainer>
        <Header></Header>
        <Transition location={location}>
          <Main className="bg-white">{children}</Main>
        </Transition>
        <Footer></Footer>
      </LayoutContainer>
    </>
  )
}

Layout.propTypes = {
  // children: PropTypes.node.isRequired,
}

export default Layout
