// Icon
import React from "react"

function Logo() {
  return (
    <svg id="Laag_1" data-name="Laag 1" viewBox="0 0 695.19 230.29">
      <path
        className="cls-1"
        d="M63.39,140.6a64.19,64.19,0,0,1-22-3.66,51.74,51.74,0,0,1-17.63-10.65,53.28,53.28,0,0,1-12.11-16.88A62.15,62.15,0,0,1,7.05,95.05l-.59-3h32.2l.55,1.82a29.41,29.41,0,0,0,6.41,11.92c4.24,4.61,10.06,6.84,17.77,6.84,5.7,0,10.58-1.08,14.47-3.25a27.43,27.43,0,0,0,9.58-8.71,38.8,38.8,0,0,0,5.5-12.54A61.62,61.62,0,0,0,94.7,73.41a66.5,66.5,0,0,0-1.76-15.29,40,40,0,0,0-5.5-12.9,27.2,27.2,0,0,0-9.58-8.77C74,34.28,69.09,33.2,63.39,33.2A26.12,26.12,0,0,0,47,38.89a25.5,25.5,0,0,0-5.47,6.41,19.39,19.39,0,0,0-1.78,3.63l-.6,1.69-32,.06.56-3.06A51.58,51.58,0,0,1,13.28,32,49.68,49.68,0,0,1,25.91,17.16a55.84,55.84,0,0,1,17.2-9.07A66.06,66.06,0,0,1,63.39,5a65.77,65.77,0,0,1,26.8,5.3,57.6,57.6,0,0,1,20.17,14.61,65.36,65.36,0,0,1,12.55,21.71,80.75,80.75,0,0,1,4.34,26.79,77.93,77.93,0,0,1-4.35,26.27,63.42,63.42,0,0,1-12.55,21.37A57.89,57.89,0,0,1,90.16,135.4,66.65,66.65,0,0,1,63.39,140.6Z"
        transform="translate(-6.46 -5)"
      />
      <path
        className="cls-1"
        d="M263.55,137.81H231V84H185.65v53.8H153.12V8h32.53V55.83H231V8h32.54Z"
        transform="translate(-6.46 -5)"
      />
      <path
        className="cls-1"
        d="M350.05,140.6c-17.45,0-31.22-4.34-40.89-12.87s-14.76-22-14.76-39.65V8h32.54V88.08a54.37,54.37,0,0,0,.84,9.52,16.79,16.79,0,0,0,10,12.88c3,1.4,7.08,2.1,12.26,2.1,8.95,0,15.12-1.88,18.33-5.63s5-10.22,5-18.87V8h32.54V88.08c0,17.6-5.05,30.9-15,39.58S367.27,140.6,350.05,140.6Z"
        transform="translate(-6.46 -5)"
      />
      <path
        className="cls-1"
        d="M701.65,137.81H660.92L624.35,83.2,611.57,96.13v41.68H579V8h32.54v47.8L656.64,8h41.48l-51.55,52.1Z"
        transform="translate(-6.46 -5)"
      />
      <path
        className="cls-1"
        d="M548.21,137.68H515.67V57.58a55.26,55.26,0,0,0-.83-9.49,18.06,18.06,0,0,0-3.21-7.59,18.13,18.13,0,0,0-6.82-5.32c-2.94-1.4-7.07-2.11-12.25-2.11-8.95,0-15.12,1.91-18.32,5.66s-5,10.2-5,18.85v80.1H436.74V57.58c0-17.58,5-30.89,15-39.58s23.6-12.94,40.82-12.94C510,5.06,523.77,9.4,533.46,18s14.75,22,14.75,39.63Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M58.75,211a25.15,25.15,0,0,1,1.5-8.48,23.57,23.57,0,0,1,4.35-7.55,22.69,22.69,0,0,1,7-5.42,20.81,20.81,0,0,1,9.47-2.09,19.34,19.34,0,0,1,10.77,2.89,18.21,18.21,0,0,1,6.65,7.42l-2.79,1.59a15.83,15.83,0,0,0-3-4.12,15.26,15.26,0,0,0-3.66-2.69A16.1,16.1,0,0,0,85,191a19.55,19.55,0,0,0-4.18-.47,16.9,16.9,0,0,0-8,1.83A18.77,18.77,0,0,0,67,197.13a20.57,20.57,0,0,0-3.63,6.61,23.32,23.32,0,0,0-1.23,7.42,22,22,0,0,0,1.46,8,22.43,22.43,0,0,0,4,6.69,19.33,19.33,0,0,0,6,4.62A16.41,16.41,0,0,0,81,232.17a18.82,18.82,0,0,0,4.39-.54A16.57,16.57,0,0,0,89.73,230a17.53,17.53,0,0,0,3.86-2.89,15.3,15.3,0,0,0,3-4.16l2.93,1.4A15.88,15.88,0,0,1,96.25,229a20.06,20.06,0,0,1-4.59,3.46,21.63,21.63,0,0,1-5.35,2.09,22.82,22.82,0,0,1-5.55.7,18.67,18.67,0,0,1-8.88-2.13,23.28,23.28,0,0,1-6.95-5.55,25.73,25.73,0,0,1-4.55-7.78A25,25,0,0,1,58.75,211Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M107.88,234.89v-47.2H127.5A12.23,12.23,0,0,1,133,189a14,14,0,0,1,4.35,3.36,16,16,0,0,1,2.86,4.72,14.29,14.29,0,0,1,1,5.28,15.7,15.7,0,0,1-.8,5,14.68,14.68,0,0,1-2.26,4.36,12.76,12.76,0,0,1-3.52,3.19,12.31,12.31,0,0,1-4.52,1.66l11.77,18.35H138l-11.43-17.82H111.28v17.82Zm3.4-20.94h16.55a8.51,8.51,0,0,0,4.12-1,10.37,10.37,0,0,0,3.19-2.62,11.53,11.53,0,0,0,2-3.73,13.3,13.3,0,0,0,.7-4.29A11.76,11.76,0,0,0,137,198a12.33,12.33,0,0,0-2.26-3.69,11.24,11.24,0,0,0-3.33-2.56,8.88,8.88,0,0,0-4.08-1H111.28Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M182.48,231.83v3.06H151v-47.2h30.85v3.06H154.42v18.54h24v2.93h-24v19.61Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M206.55,187.69h2.93l19.94,47.2h-3.59l-6.58-15.56H196.71l-6.58,15.56h-3.66Zm11.77,28.85L208,191.81l-10.5,24.73Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M264.93,190.75H247.64v44.14h-3.39V190.75H227v-3.06h38Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M272.24,234.89v-47.2h3.39v47.2Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M286.4,187.69l18,42.95,17.95-42.95h3.6L306,234.89h-3.13l-20-47.2Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M364.59,231.83v3.06H333.14v-47.2H364v3.06H336.53v18.54h24v2.93h-24v19.61Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M403.69,187.69h2.92l19.95,47.2H423l-6.59-15.56H393.85l-6.59,15.56h-3.65Zm11.76,28.85-10.3-24.73-10.51,24.73Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M466.78,227.51q-6.84,7.73-15.89,7.71a19.32,19.32,0,0,1-8.87-2.06,22.51,22.51,0,0,1-7-5.45,26,26,0,0,1-4.59-7.71,24.45,24.45,0,0,1-1.66-8.78,25.57,25.57,0,0,1,6.15-16.62,21.57,21.57,0,0,1,6.88-5.28,19.47,19.47,0,0,1,8.71-2,24.28,24.28,0,0,1,6.35.77,19.26,19.26,0,0,1,5.06,2.13,16.4,16.4,0,0,1,3.85,3.25,20,20,0,0,1,2.76,4.16l-2.79,1.66a15.23,15.23,0,0,0-6.35-6.75,18.9,18.9,0,0,0-8.94-2.09,16,16,0,0,0-7.58,1.79,18.71,18.71,0,0,0-5.79,4.73,21.37,21.37,0,0,0-3.66,6.64,23.73,23.73,0,0,0-1.26,7.65,21.57,21.57,0,0,0,1.47,7.94,21.24,21.24,0,0,0,4,6.62,19.43,19.43,0,0,0,6,4.55,16.59,16.59,0,0,0,7.45,1.7,17.73,17.73,0,0,0,8.21-2,24.37,24.37,0,0,0,7.48-6.32v-9.11h-10.7V212h13.63v22.94h-2.93Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M511.13,231.83v3.06H479.68v-47.2h30.85v3.06H483.07v18.54h24v2.93h-24v19.61Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M523.76,194.07v40.82h-3.39v-47.2H523l33.11,41.55V187.69h3.4v47.2h-3.13Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M568.51,211a25.39,25.39,0,0,1,1.49-8.48,23.6,23.6,0,0,1,4.36-7.55,22.53,22.53,0,0,1,7-5.42,20.85,20.85,0,0,1,9.48-2.09,19.34,19.34,0,0,1,10.77,2.89,18.28,18.28,0,0,1,6.65,7.42l-2.8,1.59a15.8,15.8,0,0,0-3-4.12,15.26,15.26,0,0,0-3.66-2.69,16.1,16.1,0,0,0-4.09-1.5,19.64,19.64,0,0,0-4.19-.47,17,17,0,0,0-8,1.83,19,19,0,0,0-5.85,4.76,20.74,20.74,0,0,0-3.62,6.61,23.32,23.32,0,0,0-1.23,7.42,22.4,22.4,0,0,0,5.45,14.66,19.29,19.29,0,0,0,6,4.62,16.48,16.48,0,0,0,7.45,1.73,18.89,18.89,0,0,0,4.39-.54,16.57,16.57,0,0,0,4.32-1.66,17.77,17.77,0,0,0,3.86-2.89,15.51,15.51,0,0,0,3-4.16l2.92,1.4A15.67,15.67,0,0,1,606,229a20.5,20.5,0,0,1-4.59,3.46,21.79,21.79,0,0,1-5.35,2.09,23,23,0,0,1-5.56.7,18.66,18.66,0,0,1-8.87-2.13,23.28,23.28,0,0,1-6.95-5.55,25.53,25.53,0,0,1-4.55-7.78A25,25,0,0,1,568.51,211Z"
        transform="translate(-6.46 -5)"
      />
      <path
        d="M615.45,187.69l16.49,26.13,16.55-26.13h3.79l-18.62,29.38v17.82h-3.39V216.94l-18.55-29.25Z"
        transform="translate(-6.46 -5)"
      />
    </svg>
  )
}

export default Logo
