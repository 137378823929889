// Icon
import React from "react"

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 28"
      fill="currentColor"
    >
      <path d="M12 10c0-2.203-1.797-4-4-4s-4 1.797-4 4 1.797 4 4 4 4-1.797 4-4zm4 0c0 .953-.109 1.937-.516 2.797L9.796 24.891C9.468 25.579 8.749 26 7.999 26s-1.469-.422-1.781-1.109L.515 12.797C.109 11.938-.001 10.953-.001 10c0-4.422 3.578-8 8-8s8 3.578 8 8z" />
    </svg>
  )
}

export default Icon
