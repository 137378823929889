// Icon
import React from "react"

function Icon() {
  return (
    <svg width="28px" height="27px" viewBox="0 0 28 27" version="1.1">
      <title>linkedin-icon-1</title>
      <g
        id="Exploration-2"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="linkedin-icon-1" fill="currentColor" fillRule="nonzero">
          <path
            d="M0,3.10910682 C0,2.20789841 0.315324324,1.46441635 0.945945946,0.878660622 C1.57656757,0.292877862 2.39640541,0 3.40540541,0 C4.39640541,0 5.19818919,0.288362898 5.81081081,0.865142766 C6.44143243,1.45992842 6.75675676,2.23493412 6.75675676,3.19021395 C6.75675676,4.05535672 6.45045946,4.776291 5.83783784,5.35307087 C5.20721622,5.94785652 4.37837838,6.24524934 3.35135135,6.24524934 L3.32432432,6.24524934 C2.33332432,6.24524934 1.53154054,5.94785652 0.918918919,5.35307087 C0.306297297,4.75828521 0,4.01028819 0,3.10910682 Z M0.351351351,26.7653543 L0.351351351,8.70549909 L6.35135135,8.70549909 L6.35135135,26.7653543 L0.351351351,26.7653543 Z M9.67567568,26.7653543 L15.6756757,26.7653543 L15.6756757,16.681034 C15.6756757,16.0501827 15.7477568,15.5635399 15.8918919,15.2211055 C16.1441351,14.6082871 16.527027,14.0900936 17.0405405,13.6665521 C17.5540541,13.2429836 18.1981892,13.0312129 18.972973,13.0312129 C20.991,13.0312129 22,14.3920014 22,17.1136053 L22,26.7653543 L28,26.7653543 L28,16.4106768 C28,13.7431443 27.3693784,11.7199809 26.1081081,10.3411596 C24.8468378,8.96233834 23.1801892,8.2729277 21.1081081,8.2729277 C18.7837838,8.2729277 16.972973,9.27324903 15.6756757,11.2738917 L15.6756757,11.3279631 L15.6486486,11.3279631 L15.6756757,11.2738917 L15.6756757,8.70549909 L9.67567568,8.70549909 C9.7117027,9.28225192 9.72972973,11.0756118 9.72972973,14.0856057 C9.72972973,17.0955725 9.7117027,21.3221464 9.67567568,26.7653543 Z"
            id="Shape"
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default Icon
