/* eslint-disable react/display-name */
import React, { useState, useRef, useEffect } from "react"
import { Link } from "gatsby"
import useComponentSize from "@rehooks/component-size"
import { Keyframes, animated } from "react-spring/renderprops"
import classNames from "classnames/bind"
import styled from "@emotion/styled"
import delay from "delay"
import OutsideClickHandler from "react-outside-click-handler"
import gsap from "gsap"
import Menu from "./menu"
import Logo from "./logo"

const Sidebar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  //background-image: linear-gradient(135deg, #ffff00, #00ffff);
`

const StyledMenu = styled.nav`
  display: inline-flex;
  flex-direction: column;
  text-align: left;
  padding: 8rem 2rem 2rem 2rem;
  z-index: 2;
  align-self: flex-start;
  margin: 0 auto;
  max-height: 100vh;
  overflow: auto;
  ul {
    list-style: none;
  }
  a {
    text-decoration: none !important;
  }
`

const Goo = styled(animated.div)`
  display: flex;
  top: 0;
  right: 0;
  height: ${props => props.height}px;
  width: ${props => props.width}px;
  position: absolute;
  border-radius: 50%;
  border-radius: 38% 62% 39% 61% / 36% 47% 53% 64%;
  transform: translate(-50%, -50%);
  transform-origin: 100% 0%;
  z-index: -1;
`

const SidebarSpring = Keyframes.Trail({
  open: async next => {
    await next({
      transform: "scale(1.0) translate3d(5%,-5%,0)",
    })
  },
  closed: async next => {
    await delay(200)
    await next({
      transform: "scale(0) translate3d(100%,-100%,0)",
    })
  },
})

const Header = () => {
  const [isOpen, setOpen] = useState(false)
  const menuRef = useRef(null)
  const logoRef = useRef(null)
  const hamburgerRef = useRef(null)
  let { height, width } = useComponentSize(menuRef)

  const w = typeof window === `undefined` ? 0 : window.innerWidth * 1.2
  height += 100
  width += 100
  height = height > width ? height : width
  width = width > height ? width : height
  width = width > w ? w : width

  useEffect(() => {
    const timeLine = gsap.timeline({ defaults: { ease: "power3.inOut" } })
    const delayAnimation = 2
    timeLine
      .fromTo(
        logoRef.current,
        {
          autoAlpha: 0,
          scaleX: 0.1,
          scaleY: 0.1,
        },
        {
          autoAlpha: 1,
          scaleX: 1,
          scaleY: 1,
          duration: 0.25,
          delay: delayAnimation,
        }
      )
      .fromTo(
        hamburgerRef.current,
        {
          autoAlpha: 0,
          scaleX: 0.1,
          scaleY: 0.1,
        },
        {
          autoAlpha: 1,
          scaleX: 1,
          scaleY: 1,
          duration: 0.25,
        }
      )
    // .to(everChildrens, { autoAlpha: 1, stagger: 0.025 })
    // .to(button, { autoAlpha: 1 })
  }, [])

  const toggleOpen = () => {
    setOpen(!isOpen)
  }

  const escFunction = event => {
    if (event.keyCode === 27) {
      setOpen(false)
    }
  }

  const maskClass = classNames({
    hideMenu: !isOpen,
    showMenu: isOpen,
  })

  const logoClass = classNames({
    "navbar-brand black text-center": true,
  })

  const togglerClass = classNames({
    "navbar-toggler hamburger hamburger--squeeze": true,
    collapsed: !isOpen,
    "is-active": isOpen,
  })

  const navbarClass = classNames({
    "navbar navbar-expand-always navbar-light absolute-top": true,
    "": isOpen,
  })

  const items = [
    {
      key: "1",
    },
    {
      key: "2",
    },
    {
      key: "3",
    },
  ]

  return (
    <>
      {isOpen && (
        <div
          className={maskClass}
          role="presentation"
          onClick={event => {
            event.preventDefault()
            setOpen(false)
            return false
          }}
          onKeyDown={event => escFunction(event)}
        />
      )}
      <OutsideClickHandler
        onOutsideClick={() => {
          setOpen(false)
        }}
      >
        <nav className={navbarClass}>
          <Link
            className={logoClass}
            to="/"
            aria-label="Home"
            ref={logoRef}
            style={{ opacity: 0 }}
          >
            <Logo className="brand__logo black"></Logo>
          </Link>
          <button
            className={togglerClass}
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isOpen}
            aria-label="Toggle navigatie"
            onClick={() => toggleOpen()}
            ref={hamburgerRef}
            style={{ opacity: 0 }}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
          <div
            className="navbar-collapse collapse show"
            id="navbarSupportedContent"
          >
            <Sidebar>
              <SidebarSpring
                keys={item => item.key}
                items={items}
                state={isOpen ? "open" : "closed"}
                reverse={!isOpen}
                config={{
                  mass: 1,
                  tension: 240,
                  friction: 25,
                  velocity: 8,
                  clamp: true,
                }}
              >
                {(item, i) => ({ y, ...trailprops }) => (
                  <Goo
                    width={width}
                    height={height}
                    className={`goo goo-${i}`}
                    style={trailprops}
                  >
                    {i === 0 && (
                      <StyledMenu ref={menuRef}>
                        <Menu
                          isOpen={isOpen}
                          onClose={() => setOpen(false)}
                        ></Menu>
                      </StyledMenu>
                    )}
                  </Goo>
                )}
              </SidebarSpring>
            </Sidebar>
          </div>
        </nav>
      </OutsideClickHandler>
    </>
  )
}

export default Header
