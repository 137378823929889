import React from "react"
import { isSafari } from "react-device-detect"
import {
  TransitionGroup,
  Transition as ReactTransition,
} from "react-transition-group"

const timeout = 500
const getOtherBrowserStyles = {
  entering: {
    // position: `absolute`,
    opacity: 0,
    // transform: "translate3d(0,300px,0)",
  },
  entered: {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: 1,
    // transform: "translate3d(0,0,0)",
  },
  exiting: {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: 0,
    transform: "translate3d(0,-300px,0)",
  },
}

const getSafariStyles = {
  entering: {
    position: `absolute`,
    opacity: 0,
  },
  entered: {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: 1,
  },
  exiting: {
    transition: `all ${timeout}ms ease-in-out`,
    opacity: 0,
    transform: "translate3d(0,-300px,0)",
  },
}

const getTransitionStyles = status => {
  return isSafari ? getSafariStyles[status] : getOtherBrowserStyles[status]
}
function Transition({ children, location }) {
  return (
    <TransitionGroup>
      <ReactTransition
        key={location.pathname}
        timeout={{
          enter: timeout,
          exit: timeout,
        }}
      >
        {status => {
          return (
            <div
              className="page"
              style={{
                ...getTransitionStyles(status),
              }}
            >
              {children}
            </div>
          )
        }}
      </ReactTransition>
    </TransitionGroup>
  )
}

export default Transition
