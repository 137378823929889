// Icon
import React from "react"

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Laag 1"
      viewBox="0 0 22.57 15.48"
    >
      <defs />
      <path
        fill="currentColor"
        d="M12.77.19a.5.5 0 00.08.7l7.83 6.34H.5a.5.5 0 000 1h20.2l-7.85 6.36a.5.5 0 10.63.77l8.89-7.2a.5.5 0 00.18-.39.51.51 0 000-.13.09.09 0 010-.05.51.51 0 00-.18-.27L13.48.11a.51.51 0 00-.71.08z"
      />
    </svg>
  )
}

export default Icon
